.nav {
  background-color: transparent;
  padding: 10px;
  position: fixed;

  div {
    display: flex;
    flex-direction: row;

    &:before,
    &:after {
      content: "";
      flex: 1 1;
      border-bottom: 1px solid var(--light);
      margin: auto;
    }
  }

  a {
    padding-top: 5px;
    margin: 0px 15px;
    border-radius: 50px;
    width: 40px;
    height: 40px;
    cursor: pointer;

    i,
    img {
      color: var(--light);
      font-size: 22px;
      filter: drop-shadow(5px 5px 10px var(--dark));
      cursor: pointer;
    }

    img {
      padding-top: 5px;
    }

    &:hover {
      background-color: red;
    }
  }
}

.mint-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 60px;
  background: var(--dark);
  border-radius: 20px;
  border: 1px solid var(--light);

  @media screen and (max-width: 380px) {
    padding: 25px !important;
  }

  .content {
    align-self: center;
  }
}

footer {
  background: var(--accent);
  margin: 0;
  bottom: 0;
  position: absolute;
  width: 100%;

  div {
    font-weight: 600;
    margin: 15px 0px;
    color: var(--light);
  }

  a {
    cursor: pointer;
    transition: 0.25s ease;

    i {
      cursor: pointer;
      background: linear-gradient(-180deg, #663984 10%, #ec6565 84%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 28px;
      transition: 0.25s ease;

      &:hover {
        color: var(--secondary);
      }
    }
  }
}
