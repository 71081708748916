@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap&family=ZCOOL+KuaiLe&display=swap");

:root {
  --primary: #bb1d1d;
  --primary-rgb: 43, 3, 127;

  --secondary: #e2a918;
  --secondary-rgb: 247, 110, 143;

  --dark: #121011;
  --dark-rgb: 18, 16, 17;

  --light: #ffffff;
  --light-rgb: 255, 255, 255;
}

* {
  cursor: default;
  font-family: "Space Grotesk", sans-serif;
  color: var(--light);
}

html {
  overflow: hidden;
}

body {
  background-color: var(--dark);
}

.hover-img {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.title {
  text-transform: uppercase;
  font-family: "ZCOOL KuaiLe", cursive;
  font-size: 36px;
  color: var(--primary);
}

.primary-btn {
  font-size: 24px;
  letter-spacing: 1px;
  font-family: "ZCOOL KuaiLe", cursive;
  background-color: var(--primary);
  color: var(--light);
  border: 2px solid transparent;
  border-radius: 5px;
  outline: none;
  min-width: 200px;
  padding: 5px 15px;
  transition: 0.25s ease;
}

.primary-btn i,
.primary-btn img {
  cursor: pointer;
}

.primary-btn:disabled {
  cursor: not-allowed;
}

.primary-btn:hover {
  transform: translateY(-2px);
  border: 2px solid var(--dark);
  color: var(--light);
}

.buy-qty-text {
  font-family: "ZCOOL KuaiLe", cursive;
  color: var(--primary);
  font-size: 48px;
  margin: 0px 35px 0px 30px;
}

.plus-minus-btn {
  outline: none;
  font-family: "ZCOOL KuaiLe", cursive;
  text-align: center;
  width: 50px;
  height: 50px;
  background: transparent;
  padding-bottom: 10px;
  border-radius: 50px;
  transition: 0.25s ease;
}

.plus-minus-btn div {
  cursor: pointer;
  font-family: "ZCOOL KuaiLe", cursive;
  font-size: 24px;
  transform: scale(2);
  color: var(--primary);
  transition: 0.25s ease;
}

.plus-minus-btn:hover {
  background: var(--primary);
}

.plus-minus-btn:hover div {
  color: var(--dark) !important;
}

.qty {
  font-family: "ZCOOL KuaiLe", cursive;
  font-size: 48px;
}

.cost {
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
}

::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 992px) {
  .title {
    font-size: 42px;
    line-height: 36px;
  }

  .qty {
    font-size: 36px;
  }

  .primary-btn {
    min-width: 280px;
    font-size: 18px;
  }
}

@media (pointer: coarse) {
  .hover-img {
    height: 22vh;
  }

  .hover-img {
    background-repeat: no-repeat !important;
  }
}
